import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import clsx from "clsx";
import "sweetalert2/dist/sweetalert2.min.css";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import HomeScreenImg from "@/assets/earth.jpg";
import Logo from "@/assets/tableLogo.svg";
import lotteryspinner from "@/assets/lotteryspinner.svg";
import { theme } from "@/components/Elements/theme";
import { useQuery } from "react-query";
import Notes from "./Notes";
import { BorderBottom } from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import StaticFAQ from "@/features/Faq/components/staticFAQ";
import trophy from "@/assets/trophy.png";
import { useAuth } from "@/lib/auth";
import { getLotteryCountById, useLotteryById } from "../api/getLotteryById";
import {
  lotteryDataValue,
  lotteryDataDTO,
  lotteryDataFnData,
} from "../api/postLottery";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { any, string } from "zod";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import yellowt from "@/assets/yellow-t.png";
import whitet from "@/assets/white-t.png";
import "../../../components/Elements/CSS/style.css";
import { useLotteryCountById } from "../api/getLotteryCount";
import { axios } from "@/lib/axios";
import storage from "@/utils/storage";
import { yellow, blue, red, orange, green, brown, pink, purple } from '@mui/material/colors';

interface SpinningBallData {
  _id: string;
  endDate: string;
  endTime: string;
  lotteryDrawDate: string;
  randomGeneratedBall: number;
  availableBalls: number[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
  first: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 407,
    paddingBottom: "0 !important",
    [theme.breakpoints.down("sm")]: {
      height: "295px",
    },
  },
  second: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 150,
  },
  parentPaper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 1600,
  },
  availableTickets: {
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    height: 76,
    [theme.breakpoints.down("md")]: {
      height: "52px",
    },
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
    [theme.breakpoints.down("md")]: {
      height: "160px",
      marginTop: "10px",
    },
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    color: "#fff",
  },
  logoTable: {
    [theme.breakpoints.down("md")]: {
      height: "45px",
      width: "45px",
    },
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    BorderBottom: "1px solid #0D0D39",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  logo: {
    width: 50,
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "10px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "30px !important",
    },
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  innerGridFirst: {
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    maxHeight: "282px",
    borderRadius: "20px",
    // borderTopLeftRadius: "20px",
    // borderBottomLeftRadius: "20px",
    maxWidth: "100%",
    marginInline: "auto",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      // maxHeight: "fit-content",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "10px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "7px",
    },
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "5px 10px",
    },
  },

  ballContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  ballRow: {
    display: 'flex',


    margin: theme.spacing(-1),
  },
  ball: {
    width: 21, // Adjust width of the ball
    height: 21, // Adjust height of the ball
    fontSize: 11, // Adjust font size of the ball number
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    margin: theme.spacing(-0.5),
    backgroundImage: (props: any) => `linear-gradient(135deg, ${props.color} 30%, ${props.colorDark} 90%)`,
    transition: 'transform 0.3s ease-in-out', // Transition for animation
    '&:hover': {
      transform: 'scale(1.9)', // Scale up on hover
    },
  },
  selectedBall: {
    transform: 'scale(2.0)',
    border: '2px solid white',
  },
  ballText: {
    marginTop: theme.spacing(1),
    fontSize: 12,
  },

  checkboxLabel: {
    fontSize: "12px", // Adjust the font size of the label
    color: "#ffffff", // Text color
    "& .MuiSvgIcon-root": {
      fontSize: "16px", // Adjust the size of the checkbox icon
      fill: "#ffffff", // Ensuring the checkbox box is white
    },
    "& .MuiTypography-body1": {
      fontSize: "12px", // Adjust the font size of the label text (optional)
    },
  },


}));

const PrettoSlider = styled(Slider)({
  color: "#3C37FF",
  height: 2,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 10,
    width: 10,
    backgroundColor: "#fff",
    border: "10px solid rgba(255, 255, 255, 0.5)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "10% 10% 10% 10",
    backgroundColor: "#3C37FF",
    transformOrigin: "bottom left",
  },
});

const dataValue = {
  heading: "Buy Ticket",
  subHeading: "",
};

type ticketValues = {
  price: number;
  ticketNumber: number;
  speed: number;
  deceleration: number;
  no_of_token: number;
  type: string;
  redeemCode: string,
  freeTicketAmt: string
};

const initialValues: ticketValues = {
  price: 0,
  ticketNumber: 0,
  speed: 0,
  deceleration: 0,
  no_of_token: 0,
  type: "",
  redeemCode: "",
  freeTicketAmt: ""
};

export default function BuyNow() {
  const classes = useStyles({});
  const { user } = useAuth();
  const { id } = useParams();
  const LotteryQuery = useLotteryById({ id });
  const lotteryData = LotteryQuery?.data?.data;
  const [totalSoldTicket, settotalSoldTicket] = useState(0);
  const [jackpotSelected, setJackpotSelected] = useState(false);
  const [LotteryData, setLotteryData] = useState("");
  console.log("LotteryDataLotteryDataLotteryDataLotteryData", LotteryData)
  const [selectedBall, setSelectedBall] = useState(null);
  let availableTickets =
    (lotteryData?.numOfTicketOnSale || 0) - totalSoldTicket;

  useEffect(() => {
    getData();
    availableTickets = (lotteryData?.numOfTicketOnSale || 0) - totalSoldTicket;
  }, []);



  useEffect(() => {
    const getBallData = async () => {
      let token = storage.getToken();

      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/admin/fetchSpinningBalls/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setSpinningBallData(res.data[0]);

      } catch (err) {
        console.log(err);
      }
    };

    getBallData();
  }, [id]);



  useEffect(() => {
    const getLotteryData = async () => {
      let token = storage.getToken();

      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/lotteryDetailById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setLotteryData(res.data.jackpotSelected);

      } catch (err) {
        console.log(err);
      }
    };

    getLotteryData();
  }, [id]);


  const getData = async () => {
    let token = storage.getToken();
    // let formData
    // useLotteryTicketList({lotteryId:"649d4943c0a0d39915560799"})

    await axios
      .get(`${process.env.API_URL_PREFIX}/api/v1/user/ticketcount/` + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        settotalSoldTicket(res.data[0].totalSoldLottery);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  // const countDownDate = new Date(lotteryData?.endDate).getTime();

  // const [countDown, setCountDown] = useState(
  //   countDownDate - lotteryData?.startDate
  // );
  const [formValues, setFormValues] = useState<ticketValues>(initialValues);
  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  console.log(totalAmount);


  const [jackpotAmount, setJackpotAmount] = useState(0);
  // const [totalPay , setTotalPay] = useState(0);
  const [InputValue, setInputValue] = useState(3);
  const [SpeedValue, setSpeedValue] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  //let drawDate = lotteryData?.lotteryDrawDate.getDate()
  const countDownDate = new Date(lotteryData?.endDate || 0).getTime();
  const [countDown, setCountDown] = useState(
    new Date().getTime() - countDownDate
  );
  const [spinningBallData, setSpinningBallData] = useState<SpinningBallData | null>(null);
  console.log(spinningBallData?.availableBalls, "spinningBallData")


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const totalPay = totalAmount + jackpotAmount

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };


  const ticketPrice: any = lotteryData?.ticketPrice || 0;
  const updateAmounts = (tokenCount, isJackpotSelected) => {
    const baseAmount: any = tokenCount * ticketPrice;
    const jackpotAmount = tokenCount * 0.5;

    setJackpotAmount(isJackpotSelected ? jackpotAmount : 0);
    setTotalAmount(baseAmount);
  };

  useEffect(() => {
    updateAmounts(formValues.no_of_token, jackpotSelected);
  }, [formValues.no_of_token, jackpotSelected]);

  const handleCount = (increment) => {
    const newTokenCount = formValues.no_of_token + increment;

    if (newTokenCount <= availableTickets && newTokenCount >= 0) {
      setCount((prevCount) => prevCount + increment);
      setFormValues((prevValues) => ({
        ...prevValues,
        no_of_token: newTokenCount,
      }));
    } else {
      Swal.fire({
        icon: "error",
        text: "You can select up to Available Ticket",
        color: "#fff",
        background: "black",
      });
    }
  };
  const handleAddOne = () => handleCount(1);

  const handleSubtractOne = () => handleCount(-1);

  const handleAddTen = () => handleCount(10);

  const handleSubtractTen = () => handleCount(-10);


  // the loader html
  //var sweet_loader = '<div id="loader-container"><!-- Loader HTML --><div class="sweet_loader"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div></div>';
  var sweet_loader =
    '<div class="circular-progress"><div class="circle"></div><div class="mask full"><div class="fill"></div></div><div class="mask half"><div class="fill"></div><div class="fill fix"></div></div></div>';
  const showLoadingPopup = () => {
    Swal.fire({
      title: "We are processing your purchase, one moment please",
      allowOutsideClick: false,

      showCancelButton: false,
      html: sweet_loader,
      customClass: "buy-now-pop-up",
      background: "black",
      color: "#ffff",
      showLoaderOnConfirm: true,
      timer: 1000000,
      showConfirmButton: false,
      timerProgressBar: true,
    }).then(
      function () { },
      // handling the promise rejection
      function (dismiss) {
        if (dismiss === "timer" && !isLoading) {
        }
      }
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormValues({
      ...formValues,
      speed: SpeedValue,
      deceleration: InputValue,
    });
    let data = {
      speed: SpeedValue,
      deceleration: InputValue,
      numOfToken: formValues.no_of_token,
      type: "crypto",
      redeemCode: "",
      freeTicketAmt: "",
      jackpotStatus: jackpotSelected,
      selectedJackpotColourNumber: selectedBall || "",
    };

    let idData: any = lotteryData?._id;
    handleLotteryDataValue(data, idData);
  };
  const generateLotteryAvailableTicket = () => {
    let num: any = lotteryData?.numOfTicketOnSale || 0;
    let num2 = lotteryData?.numOfTicketSold || 0;
    let num3 = num - num2;
    return num3;
  };
  const handleLotteryDataValue = async (
    data: {
      speed: number;
      deceleration: number;
      numOfToken: number;
      type: string;
      jackpotStatus: Boolean;
      selectedJackpotColourNumber: string;
    },
    idData: any
  ) => {
    try {
      setIsLoading(true);
      // Make the API request using lotteryDataValue function
      await lotteryDataValue({
        data,
        idValue: idData,
        message: "Purchased Lottery Successfully",
      }).then((res) => {
        Swal.fire({
          background: "black",
          color: "#ffff",
          icon: "success",
          title: "Success!",
          text: res.message,
        }).then(() => {
          window.location.assign("/");
        });
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: `${error?.response?.data?.message}`,
      });
    }
  };

  if (!LotteryQuery?.data) return <h4>No List Found</h4>;
  const heightStyles = {
    xs: '1vw',
    md: '1.3vw',
  };
  const sendBallNumber = (number) => {
    console.log(number, "number")
  };


  const handleBallClick = (number) => {
    setSelectedBall((prevSelectedBall) => {
      // Toggle selection based on the current state
      if (prevSelectedBall === number && jackpotSelected) {
        setJackpotSelected(false); // Deselect the checkbox when deselecting the ball
        return null; // Deselect the ball
      } else {
        setJackpotSelected(true); // Enable jackpot when selecting a ball
        return number; // Select the clicked ball
      }
    });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setJackpotSelected(isChecked);
    updateAmounts(formValues.no_of_token, isChecked);
    if (!e.target.checked) {
      setSelectedBall(null); // Deselect ball when jackpot is unchecked
    }
  };


  const colors = [
    { light: yellow[700], dark: yellow[900] },
    { light: blue[700], dark: blue[900] },
    { light: red[700], dark: red[900] },
    { light: pink[900], dark: pink[800] },
    { light: orange[700], dark: orange[900] },
    { light: green[700], dark: green[900] },
    { light: purple[700], dark: purple[900] },
  ];



  const Ball = ({ number, color, colorDark, isSelected, onClick }) => {
    const ballStyles = useStyles({});

    return (
      <Box display="flex" flexDirection="column" alignItems="center" margin={1}>
        <Box
          className={`${ballStyles.ball} ${isSelected ? ballStyles.selectedBall : ''}`}
          style={{ backgroundColor: isSelected ? colorDark : color }}
          onClick={() => onClick(number)}
        >
          {number}
        </Box>
      </Box>
    );
  };

  const BallRow = ({ selectedBall, onBallClick, availableBalls }) => {
    return (
      <Box className={useStyles({}).ballRow}>
        {availableBalls && availableBalls?.map((ballNumber, index) => (
          <Ball
            key={index}
            number={ballNumber}
            color={colors[ballNumber - 1].light}
            colorDark={colors[ballNumber - 1].dark}
            isSelected={selectedBall === ballNumber}
            onClick={onBallClick}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        paddingTop: { xs: "65px", md: "105px" },
      }}
    >
      <Box
        sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
      />
      <Box sx={{ padding: { md: theme.spacing(0.5), xs: theme.spacing(0.5) } }}>
        {isLoading && <>{showLoadingPopup()}</>}
        <div className={classes.root}>
          <Grid
            sx={{ width: "100%", mx: "auto", columnGap: "20px" }}
            container
            spacing={3}
            justifyContent="space-around"
            // justifyContent="space-around"
            className={classes.myGridItem}
          >
            <Grid
              sx={{
                p: { xs: "0px !important", md: "0 px!important" },
                mb: { xs: "20px !important", md: "0 !important" },
                display: "flex",
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className={classes.innerGrid}
            >
              <Grid
                container
                className="Lotteries-buy-page"
                style={{ margin: "auto", flexFlow: "row no-wrap", justifyContent: "space-between", alignItems: "center" }}
              >
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <Box style={{ position: "relative", textAlign: "center" }}>
                    <img
                      className={classes.innerGridFirst}
                      alt="lottery-name"
                      // eslint-disable-next-line no-template-curly-in-string
                      src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${lotteryData?.image}`}
                    ></img>
                    {/* <Typography variant="body1" align='center' sx={{
position:"absolute",
top: "70%",
left: "20%",
background: "black",
padding: "13px",
borderRadius: "32px"
}}>
   {lotteryData?.lotteryName}</Typography> */}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sm={7}
                  md={6}
                  lg={6}
                  className={classes.innerGridSecond}
                >
                  <Grid container sx={{ mt: { md: "21px", xs: "5px" } }}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "x-large",
                      }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{
                          textTransform: "uppercase",
                          color: "white",
                          fontSize: "2vw",
                          fontWeight: 700,
                        }}
                      >
                        {lotteryData?.lotteryName
                          ? lotteryData?.lotteryName
                          : ""}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ color: "white" }}
                      >
                        <span
                          style={{
                            fontSize: "1.8vw",
                            fontWeight: 700,
                            height: "10px",
                          }}
                        >
                          {" "}
                          Ticket Price :{" "}
                        </span>
                        <img
                          style={{ height: heightStyles.xs }}
                          src={whitet}
                          alt="currency"
                        />{" "}
                        <span
                          style={{
                            fontSize: "1.5vw",
                            fontWeight: 700,
                            height: "10px",
                          }}
                        >
                          {lotteryData?.ticketPrice
                            ? lotteryData?.ticketPrice
                            : " "}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        align="center"
                        sx={{
                          color: "#FFBC00",
                          mt: { xs: "1.5vw", md: "1.5vw" },
                          fontSize: {
                            xs: "12px",
                            sm: "14px",
                            md: "14px",
                            lg: "16px",
                          },
                          lineHeight: "1.5",
                        }}
                      >
                        DRAW DATE : {`${lotteryData?.endDate}`}
                        <br />
                        DRAW TIME :{" "}
                        {`${lotteryData?.endTime?.substring(0, 5)} UTC`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{
                          fontWeight: "700",
                          fontSize: {
                            xs: "25px !important",
                            md: "32px !important",
                            lg: "45px !important",
                          },
                        }}
                      >
                        <Countdown
                          date={
                            new Date(
                              `${lotteryData?.endDate}T${lotteryData?.endTime}`
                            )
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{ fontSize: "20px", fontWeight: "700" }}
                      >
                        Total tickets :
                        <CurrencyFormat
                          value={lotteryData?.numOfTicketOnSale}
                          thousandSeparator={"."}
                          displayType={"text"}
                          decimalSeparator={","}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              className={classes.innerGrid}
            >
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
                sx={{
                  maxHeight: { xs: "150px !important", md: "255px" },
                  minHeight: "100%",
                }}
              >
                <Table
                  className={classes.table}
                  aria-label="custom table"
                  sx={{
                    p: { xs: "10px", sm: "25px" },
                    minWidth: "auto !important",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#0D0D36 !important" }}>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "20px" } }}
                      >
                        <img
                          src={Logo}
                          alt="table logo"
                          className={classes.logoTable}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "20px" } }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            color: "#FFFFFF",
                            p: {
                              sm: "0px !important",
                              md: "0px !important",
                              lg: "0px !important",
                            },
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          Total Prize-pool :
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "20px" } }}
                      >
                        <Typography
                          variant="h1"
                          gutterBottom
                          sx={{
                            color: "#FFFFFF",
                            p: {
                              sm: "16px !important",
                              md: "0px !important",
                              lg: "0px !important",
                            },
                            textAlign: "end",
                            float: "right",
                            display: "flex",
                          }}
                        >
                          <img
                            style={{
                              height: "auto",
                              width: "24px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                            src={whitet}
                          />
                          {/* {lotteryData?.drawPrize.reduce(
                            (acc, curr) => acc + parseInt(curr),
                            0
                          )} */}
                          <CurrencyFormat
                            value={lotteryData?.drawPrize.reduce(
                              (acc, curr) => acc + parseInt(curr),
                              0
                            )}
                            thousandSeparator={"."}
                            displayType={"text"}
                            decimalSeparator={","}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lotteryData?.drawPrize?.map((row, index) => (
                      <TableRow key={index + 1}>
                        <TableCell
                          colSpan={2}
                          className={classes.tableData}
                          sx={{ p: "4px" }}
                        >
                          <ul style={{ margin: "0px" }}>
                            <li>{index + 1 + "th spin"}</li>
                          </ul>
                        </TableCell>
                        {/* <TableCell className={classes.tableData}></TableCell> */}
                        <TableCell
                          className={classes.tableData}
                          sx={{ textAlign: "center", p: "4px" }}
                        >
                          <span>
                            <img
                              alt="yellow dolar"
                              style={{
                                height: "auto",
                                width: "17px",
                                marginRight: "4px",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                              src={yellowt}
                            />
                            {/* {lotteryData?.drawPrize[index]} */}
                            <CurrencyFormat
                              value={lotteryData?.drawPrize[index]}
                              thousandSeparator={"."}
                              displayType={"text"}
                              decimalSeparator={","}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>


          <Grid
            container
            spacing={3}
            sx={{ mt: { xs: "-5px", sm: "-5px", md: "10px" } }}
          >
            <Grid item xs={12} container sx={{ justifyContent: "center" }}>
              <Grid item xs container direction="row" spacing={3} >

                <Grid item xs={12} md={6} lg={4} className="small-device-w100">



                  <Paper
                    className={clsx(classes.first, classes.paperClass)}
                    sx={{
                      p: {
                        xs: "20px 18px !important",
                        md: "40px 36px !important",
                      },
                      height: "500px !important",// Ensuring paper adjusts its height based on content
                      overflow: "hidden", // Prevent content from overflowing
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                    }}

                  >
                    <Grid container >
                      <Grid item xs={12} >
                        <Grid
                          container
                          className={classes.availableTickets}
                          sx={{ p: { xs: "12px", md: "20px" } }}
                        >
                          {/* <Grid item xs={8} md={8}>
            {" "}
            <Typography
              variant="subtitle2"
              textAlign="left"
              sx={{ mb: "10px" }}
            >
              Available Tickets :
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "700", mb: "10px" }}
              textAlign="right"
            >
              {(lotteryData?.numOfTicketOnSale || 0) - totalSoldTicket}
            </Typography>
          </Grid> */}
                          {/* <Grid item xs={12} md={12}>
            {" "}
            <Divider
              sx={{
                background: "rgba(43, 44, 68, 0.5)",
                mb: "11px",
              }}
            />
          </Grid> */}
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography
                              variant="subtitle2"
                              textAlign="left"
                              sx={{ fontWeight: "700" }}
                            >
                              Price :
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography
                              variant="h4"
                              sx={{ fontWeight: "700" }}
                              textAlign="right"
                            >
                              {lotteryData?.ticketPrice}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Box className={classes.noOfTickets} >
                      <Typography
                        variant="h5"
                        textAlign="center"
                        sx={{ marginTop: "20px" }}

                      >
                        Select No. of Tickets:
                      </Typography>
                      <Stack
                        flexDirection="column"
                        alignItems="center"
                        sx={{ mt: "13px" }}
                      >
                        <ButtonGroup sx={{ mb: "11px" }}>
                          <Button
                            className={classes.substract}
                            onClick={handleSubtractOne}
                          >
                            <RemoveIcon sx={{ fontSize: "15px" }} />
                          </Button>
                          <Button
                            className={classes.minimumTicket}
                            sx={{
                              borderRadius: "10px !important",
                              color: "#ffff",
                              mx: "12px !important",
                            }}
                          >
                            {" "}
                            <span style={{ color: "#ffff !important" }}>1</span>
                          </Button>
                          <Button
                            className={classes.substract}
                            onClick={handleAddOne}
                          >
                            <AddIcon sx={{ fontSize: "15px" }} />
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                          <Button
                            className={classes.substract}
                            onClick={handleSubtractTen}
                          >
                            <RemoveIcon sx={{ fontSize: "15px" }} />
                          </Button>
                          <Button
                            sx={{
                              borderRadius: "10px !important",
                              mx: "12px !important",
                              background: "#ffff !important",
                            }}
                            disabled
                          >
                            <span style={{ color: "#ffff !important" }}>
                              10
                            </span>
                          </Button>
                          <Button
                            className={classes.substract}
                            onClick={handleAddTen}
                          >
                            <AddIcon sx={{ fontSize: "15px" }} />
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Box>

                    {LotteryData && (
                      <Grid container alignItems="center">
                        {/* Checkbox Grid Item */}
                        <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
                          <FormControlLabel
                            className={classes.checkboxLabel}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#ffffff",
                                }}
                                checked={jackpotSelected}
                                onChange={handleCheckboxChange}
                                id="jackpotSelected"
                              />
                            }
                            label="Enable Jackpot"
                          />
                        </Grid>

                        {/* Balls Grid Item */}
                        <Grid item xs={12} sm={6}>
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <BallRow selectedBall={selectedBall} onBallClick={handleBallClick} availableBalls={spinningBallData?.availableBalls} />
                          </Box>
                        </Grid>
                      </Grid>)

                    }







                    <Grid container>
                      <Grid item xs={12}>


                        <Grid
                          container
                          className={classes.availableTickets}
                          sx={{
                            p: { xs: "10px", md: "20px" },
                            maxHeight: "70px",
                            display: "flex",
                            marginBottom: "20px"
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            md={8}
                            sx={{
                              maxHeight: "20px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {" "}
                            <Typography
                              variant="subtitle2"
                              textAlign="left"
                              sx={{ lineHeight: "normal" }}
                            >
                              Total Tickets{" "}
                            </Typography>
                          </Grid>


                          <Grid
                            item
                            xs={4}
                            md={4}
                            sx={{
                              maxHeight: "20px",
                              display: "flex",
                              margin: "auto",
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: "700",
                                margin: "auto",
                                lineHeight: "0.1",
                              }}
                              textAlign="right"
                            >
                              {formValues.no_of_token}
                            </Typography>
                          </Grid>


                        </Grid>


                      </Grid>
                    </Grid>
                  </Paper>




                </Grid>

                <Grid item xs={7} md={7} lg={8}>
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    spacing={2}
                    className="small-device-m-auto"
                  >
                    <Grid item xs={12}>
                      <Grid item xs container direction="row" spacing={1}>
                        <Grid item xs={6} md={6}>
                          <Paper
                            className={classes.paperClass}
                            sx={{ height: { xs: 165, md: 250 }, p: "20px" }}
                          >
                            <Stack
                              spacing={2}
                              direction="column"
                              sx={{ mb: 1 }}
                              alignItems="flex-start"
                            >
                              <Typography
                                variant="h6"
                                component="h6"
                                // textAlign="left"
                                sx={{
                                  opacity: "0.8",
                                  textAlign: { xs: "center", md: "left" },
                                }}
                              >
                                Set your wheel settings
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexDirection: {
                                    xs: "column !important",
                                    md: "row !important",
                                  },
                                }}
                              >
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant="subtitle2"
                                    // textAlign="left"
                                    sx={{
                                      textAlign: { xs: "center", md: "left" },
                                    }}
                                  >
                                    Speed
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <PrettoSlider
                                    valueLabelDisplay="auto"
                                    aria-label="speed"
                                    name="speed"
                                    defaultValue={60}
                                    value={SpeedValue}
                                    onChange={(_, value: any) =>
                                      setSpeedValue(value)
                                    }
                                    min={60}
                                    max={120}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                sx={{
                                  mt: {
                                    xs: "20px !important",
                                    md: "50px !important",
                                  },
                                  display: "flex",
                                  flexDirection: {
                                    xs: "column !important",
                                    md: "row !important",
                                  },
                                }}
                              >
                                <Grid item xs={12} md={5}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      textAlign: { xs: "center", md: "left" },
                                    }}
                                  >
                                    Decelaration
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <PrettoSlider
                                    valueLabelDisplay="auto"
                                    aria-label="deceleration"
                                    defaultValue={3}
                                    name="deceleration"
                                    value={InputValue}
                                    min={3}
                                    onChange={(_, value: any) =>
                                      setInputValue(value)
                                    }
                                    max={7}
                                  />
                                </Grid>
                              </Grid>
                            </Stack>
                          </Paper>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Paper
                            className={classes.paperClass}
                            sx={{ height: { xs: 165, md: 250 } }}
                          >
                            <img src={trophy} alt="trophy"></img>
                            <Typography variant="h5" textAlign="center">
                              Win Chance{" "}
                            </Typography>
                            <br />
                            <Typography
                              // variant="h5"
                              textAlign="center"
                              sx={{
                                fontSize: { xs: "30px", md: "40px !important" },
                                fontWeight: "bold",
                              }}
                            >
                              1 :{" "}
                              {lotteryData?.numOfTicketOnSale &&
                                formValues.no_of_token > 0
                                ? Math.round(
                                  lotteryData?.numOfTicketOnSale /
                                  (formValues.no_of_token + totalSoldTicket)
                                )
                                : lotteryData?.numOfTicketOnSale}
                            </Typography>
                            {/* <br /> */}
                            <Typography
                              variant="h5"
                              sx={{
                                lineHeight: 0.334,
                                justifyContent: "center",
                                display: { md: "flex", xs: "block" },
                              }}
                              textAlign="center"
                            >
                              <p className="m-auto">Previous Bought tickets:</p>
                              <p>
                                {/* {totalSoldTicket} */}
                                <CurrencyFormat
                                  value={totalSoldTicket}
                                  thousandSeparator={"."}
                                  displayType={"text"}
                                  decimalSeparator={","}
                                />
                              </p>
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={12} md={12}>
                          <Paper
                            className={classes.paperClass}
                            sx={{ height: { xs: 69, md: 210 } }}
                          >
                            <Grid container>
                              <Grid item xs={8} md={8}>
                                <Grid
                                  container
                                  spacing={0}
                                  className={classes.ticketPrize}
                                >
                                  <Grid item xs={8} md={8}>
                                    {" "}
                                    <Typography
                                      variant="subtitle2"
                                      textAlign="left"
                                    >
                                      Ticket Price{" "}
                                      <Box
                                        sx={{
                                          fontSize: { md: "20px", xs: "10px" },
                                          fontWeight: "700",
                                        }}
                                      >
                                        {" "}
                                        ({formValues.no_of_token} Tickets *{" "}
                                        {lotteryData?.ticketPrice})
                                      </Box>{" "}
                                    </Typography>
                                  </Grid>



                                  <Grid item xs={4} md={4}>
                                    <Typography
                                      variant="h4"
                                      sx={{ fontWeight: "700" }}
                                      textAlign="right"
                                    >
                                      {totalAmount} USDT
                                      {/* <CurrencyFormat
                                        value={totalAmount}
                                        thousandSeparator={"."}
                                        displayType={"text"}
                                        decimalSeparator={","}
                                      />{" "} */}
                                    </Typography>
                                  </Grid>



                                  {jackpotSelected && (
                                    <>
                                      <Grid item xs={8} md={8} sx={{ marginTop: "20px" }}>
                                        <Typography variant="subtitle2" textAlign="left">
                                          Jackpot Price
                                          <Box
                                            sx={{
                                              fontSize: { md: "20px", xs: "10px" },
                                              fontWeight: "700",
                                            }}
                                          >
                                            ({formValues.no_of_token} Tickets * 0.5)
                                          </Box>
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={4} md={4}>
                                        <Typography
                                          variant="h4"
                                          sx={{
                                            fontWeight: "700",
                                            marginTop: "20px",
                                            whiteSpace: "nowrap"
                                          }}
                                          textAlign="right"
                                        >
                                          {Number(jackpotAmount).toFixed(1)} Merv CF
                                        </Typography>
                                      </Grid>



                                    </>
                                  )}


                                  {/* <Grid item xs={12} md={12}>
                                    {" "}
                                    <Divider
                                      sx={{
                                        background: "rgba(43, 44, 68, 0.5)",
                                      }}
                                    />
                                  </Grid> */}
                                  {/* <Grid item xs={6} md={6}>
                                    <Typography
                                      variant="subtitle2"
                                      textAlign="left"
                                      sx={{ fontWeight: "700" }}
                                    >
                                      Total
                                    </Typography>
                                  </Grid> */}
                                  {/* <Grid item xs={6} md={6}>
                                    <Typography
                                      variant="h4"
                                      sx={{ fontWeight: "700" }}
                                      textAlign="right"
                                    >

                                      {totalPay}


                                    </Typography>
                                  </Grid> */}
                                </Grid>
                              </Grid>



                              <Grid item xs={4} md={4}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    mt: { md: 7, xs: 2 },
                                    mb: { md: 2, xs: 10 },
                                    p: { md: "14px 28px", xs: "10px 10px" },
                                  }}
                                  type="submit"
                                  disabled={isLoading}
                                  onClick={handleFormSubmit}
                                >
                                  Buy Tickets
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


              </Grid>
            </Grid>

          </Grid>



          {/* Notes Section */}
          <Box sx={{ mt: { xs: "15px", md: "25px" } }}>
            <Notes />
          </Box>
          <StaticFAQ />
        </div>
      </Box>
    </Box>
  );
}
