import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import promotionsBackground from "@/assets/promotionsBackground.png";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import { useNavigate } from "react-router-dom";
import promotionSecond from "@/assets/promotionSecond.png";
import promotionimg from "@/assets/promotionimg.png";
import { theme } from "@/components/Elements/theme";
import { url } from "inspector";
import HomeScreenImg from "@/assets/earth.jpg";

import Countdown from "react-countdown";
import { UsePromotionByReferList } from "../api/getPromotionByRefer";
import { UsePromotionByBuyList } from "../api/getPromotionByBuy";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import storage from "@/utils/storage";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridThird: {
    background: `url(${promotionSecond})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "center",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
}));


export default function PromotionFreeTicket() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dataValueByRefer = UsePromotionByReferList({});
  const dataValueByBuy = UsePromotionByBuyList({});
  const [buyPromotion,setPromotion]=useState([{
    id: "",
    rewardBy: "",
    rewardAmount: "",
    couponCode: "",
    targetUser: 0,
    sentNumTargetUser: 0,
    repeatReward: false,
    usesPerCoupon: "1",
    comments:"",
    status: "",
    minimumCartValue: "",
    discountType: "",
    usesPerPlayer: null,
    promotionName: "Free Ticket",
    promotionDetails: "",
    image: "",
    lotteryId: "",
  }]);
  console.log("dataValueByBuy",buyPromotion)
  const auth = useAuth();
  useEffect(()=>{
    getData()
  },[])
  const getData = async () => {
    let token = storage.getToken();
    try {
        let response = await axios
            .get(
                `${process.env.API_URL_PREFIX}/api/v1/user/buyPromotionList`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
        setPromotion(response.data.data)
    }
    catch (error) {
        console.log("token error", error);
    }
};
  return (
    <>
      {/* {auth.user ? <Header /> : <HeaderValue />} */}
      <Box
        sx={{
          background: `url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          paddingTop: "60px",
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid
            item
            xs={12}                                                                                                                               
            md={12}                                                                                         
            // className={classes.innerGrid}
            sx={{ pb:"30px" }}
          >
            <Grid item xs={12} justifyContent={"space-around"} p={2}>
              <Typography
                variant="h2"
                align="center"
                sx={{ mt: { md: "50px" } }}
              >
                Promotions
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}  sx={{justifyContent:"space-around",width:"100%"}} mb={4}>
            {buyPromotion.map((card, index) => (
              <Grid item sx={{width:"33%"}} key={index}>
                <Card
                  sx={{ maxWidth: 400 , marginBottom:"10px" }}
                  className={classes.cardRoot}
                  onClick={() =>  navigate(`/app/BuyFreeTicket/${card?.lotteryId}?redeemCode=${card?.couponCode}&freeTicketAmt=${card?.rewardAmount}`)}
                >
                  {/* <CardMedia
                  sx={{ height: 240 , objectFit:"contain"}}
                  component="img"
                  image={card.image ? `https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/${card.image}` : promotionCard}      // image={promotionCard}
                 
                /> */}
                  <CardMedia
                    sx={{ height: "auto","max-height": "300px","object-fit":"fill !important"  }}
                    component="img"
                    image={
                      card?.image
                        ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${card.image}`
                        : promotionCard
                    } // image={promotionCard}
                  />
                  <CardContent sx={{ height: "120px" }}>
                    {/* <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ color: "#FFFFFF", opacity: 0.5, }}
                  >
                    Reward:   <Typography variant="h3" sx={{ opacity: "0.6" }}>
                      {card?.rewardBy}
                    </Typography>
                  </Typography>
                  */}
                    <Grid container>
                      <Grid item xs={6} lg={6}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          sx={{ color: "#FFFFFF", opacity: 0.5 }}
                        >
                          Reward amount:
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h6"
                          sx={{ fontWeight: "700" }}
                        >
                          {card?.rewardAmount}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} lg={6} sx={{ textAlign: "right" }}>
                        <Typography
                          variant="caption"
                          gutterBottom
                          sx={{ color: "#FFFFFF", opacity: 0.5 }}
                        >
                          Coupon code:
                        </Typography>
                        <Typography variant="h3" sx={{ opacity: "0.6" }}>
                          {card?.couponCode}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ color: "#FFFFFF", opacity: 0.5 }}
                  >
                    Start coupon Time:
                  </Typography> */}
                    {/* <Typography variant="h3" sx={{ opacity: "0.6" }}>
                    {card?.startDate}
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ color: "#FFFFFF", opacity: 0.5 }}
                  >
                    End coupon Time:
                  </Typography>
                  <Typography variant="h3" sx={{ opacity: "0.6" }}>
                    {card?.endDate}
                  </Typography> */}
                    <Typography
                      variant="caption"
                      gutterBottom
                      sx={{ color: "#FFFFFF", opacity: 0.5 }}
                    >
                      Description:
                    </Typography>
                    <Typography variant="h3" sx={{ opacity: "0.6" }}>
                      {card?.comments}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ display: "block", p: "0" }}>
                    {" "}
                    <LinearProgress variant="determinate" value={10} />{" "}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
                                                                                      
                                                                                                          
                                                                                  
                                                                                                                       
