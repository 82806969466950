import { Close } from "@mui/icons-material";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Grid,
  Link,
  Button,
  CircularProgress,
} from "@mui/material";
import { CustomModel } from "@/components/Elements/custom-modal";
import openMail from "@/assets/closedMail.svg";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { resendOTP, verifyOTPWithOTP } from "@/features/auth/api/verifyOTP";
import { useAuth } from "@/lib/auth";
import closeModal from "@/assets/closeModal.svg";
import { getTicketStatus } from "@/features/Tickets/api/getTicketStatus";
import storage from "@/utils/storage";
import { ticketListData } from "@/features/Tickets/types";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getWalletAmount } from "../Elements/api/getWalletamount";
import Swal from "sweetalert2";
import axios from "axios";

const VerifyWithdrawOTP1 = ({ open, onClose, formValues, userData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  console.log("loading", loading);
  const [otp, setOtp] = useState("");
  const { login, isLoggingIn } = useAuth();
  // console.log("hhh OTP",otp)
  // Object.assign(formValues, otp);
  //   const [formData,setFormData]=useState({
  //     amount: "",
  //     cryptoType: "select",
  //     paymentMethodId: "",
  //     walletAddress: "",
  //     walletNetwork:"",
  //   })
  //   const [uData,setuData]=useState({
  //     userId:"",
  //     emailId:""
  //   })
  //   setFormData(formValues)
  //   setuData(userData)

  const handleChange = (e) => {
    // console.log("lll",e.target.value)
    console.log("event", e?.target?.value);
    setOtp(e?.target?.value);
  };
  // const handleFormSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     Object.assign(formValues, { otp: otp });
  //     setLoading(true);
  //     let token = storage.getToken();
  //     const res = await axios.post(
  //       `${process.env.API_URL_PREFIX}/api/v1/user/withdrawPayment`,
  //       formValues,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       }
  //     );
  //     Swal.fire({
  //       background: "black",
  //       color: "#ffff",
  //       icon: "success",
  //       title: "Success!",
  //       text: res?.data.message,
  //     }).then(() => {
  //       setLoading(false);
  //       window.location.reload();
  //     });
  //   } catch (error: any) {
  //     setLoading(false);
  //     Swal.fire({
  //       background: "black",
  //       color: "#ffff",
  //       text: error.response.data.message,
  //     }).then(() => {
  //       window.location.reload();
  //     });
  //   }
  // };
  // const handleContinue = async (e) => {
  //   const data = { otp: otp, email: email };
  //   const response =await login(data);
  //   if (storage.getToken()) {
  //     const dataWallet = await getWalletAmount()
  //     console.log("ddd",dataWallet?.data?.walletAmount);
  //     if(dataWallet?.data?.walletAmount===0){
  //       navigate("/app/wallet/MyLottery");
  //     }
  //     else{
  //       navigate("/app");
  //     }
  //     }
  //   // register(data);
  //   // onSuccess();
  //   // setisOpen(true);
  // };

  //   const handleResend = async (
  //     e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  //   ) => {
  //     e.stopPropagation();
  //     const data = { email: email };
  //     open = true;
  //     await resendOTP(data);
  //     open = true;
  //   };



  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      Object.assign(formValues, { otp: otp });
      setLoading(true); // Start loading
      let token = storage.getToken();
  
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/withdrawPayment`,
        formValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
  
      // Show success message
      Swal.fire({
        background: "black",
        color: "#ffff",
        icon: "success",
        title: "Success!",
        text: res?.data.message,
      }).then(() => {
        window.location.reload();
      });
    } catch (error: any) {
      // Show error message
      Swal.fire({
        background: "black",
        color: "#ffff",
        text: error.response?.data?.message || "Something went wrong!",
      }).then(() => {
        window.location.reload();
      });
    } finally {
      
      setLoading(false);
    }
  };
  
  return (
    <CustomModel
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Stack direction="row" height="100%" sx={{ p: 0 }}>
            <Box
              sx={{
                width: "100%",
                overflow: "auto",
                my: { md: "50px", xs: "20px" },
                ml: "50px",
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={openMail}
                alt="mailIcon"
                style={{ textAlign: "center", display: "block" }}
                width="60px"
                height="60px"
                className="image-otp"
              />

              <Typography
                component="h4"
                variant="h4"
                sx={{ mt: { md: "20px", xs: "7px" }, textAlign: "center" }}
              >
                Check your Email
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  textAlign: "center",
                  mt: { xs: "5px", md: "15px" },
                  mb: "20px",
                  ml: { xs: "15px", md: "0px" },
                }}
              >
                We sent a verification code to <br />{" "}
                <span style={{ color: "#3C37FF" }}>{userData?.emailId}</span>
              </Typography>
              <OtpInput
                // id="otp"
                // name="otp"
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span style={{ marginRight: "10px" }}></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={"input-otp"}
              />
              <Grid container>
                {/* <Grid item xs={12}>
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ textAlign: "center", mt: {xs:"25px", md:"40px" }}}
                  >
                    Didn't receive the email?
                   <Button>
                     <span  style={{ "textTransform":"capitalize" }} onClick={handleResend}>Resend</span> 
                    </Button>
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  lg={12}
                  sx={{
                    mt: { xs: 1, md: 3 },
                    mb: { xs: "30px", md: "45px" },
                    ml: { xs: "137px", md: "0px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleFormSubmit}
                      sx={{
                        px: { md: "24px !important", xs: "10px !important" },
                      }}
                    >
                      Verify
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box>
              <IconButton
                sx={{ padding: { md: "25px", xs: "10px" } }}
                onClick={onClose}
              >
                <img src={closeModal} />
              </IconButton>
            </Box>
          </Stack>
        </>
      }
    />
  );
};

export default VerifyWithdrawOTP1;
