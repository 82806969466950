import { HomeLayout } from '@/components/layout';
import { FaqMain } from '@/features/Faq/routes/FaqMain';
import { HomeScreenMain } from '@/features/Homescreen/routes/HomeScreenMain';
import { Register } from '@/features/auth/routes/Register';
import { Landing } from '@/features/misc';
import { lazyImport } from '@/utils/LazyImport';
import { Home } from '@material-ui/icons';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { About } from '../features/AboutUS/routes/About';
import { MyProfile } from '../features/MyProfile/routes/MyProfile';
import { KYCform } from '../features/KYCform/routes/KYCform';
import ContactUS from '../features/ContactUs/component/contactUs';
import Promotions from '@/features/promotions/components/promotions';
// import PromotionDetBefLogin from '../features/promotions/components/promotionDetBefLogin'
const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');

const AppHome = () => {
  return (
    <HomeLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">

          </div>
        }
      >
        <Outlet />
      </Suspense>
    </HomeLayout>
  );
};

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/',
    element: <HomeScreenMain />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/myProfile',
    element: <MyProfile />,
  },
  {
    path: '/KYCform',
    element: <KYCform />,
  },
  {
    path: '/Promotions',
    element: <Promotions />,
  },
  // {
  //   path: '/PromotionsDetail',
  //    element: <PromotionDetBefLogin />
  // },
  
  // {
  //   path: '/landing',
  //   element: <Landing />,
  // },
  {
    path: '/home',
    element: <AppHome />,
    children: [
      { path: '/home/faq', element: <FaqMain /> },
    ],
  },

];